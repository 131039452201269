import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { useNotification } from './contexts/NotificationContext';
import PlayerDetails from './PlayerDetails';

function H2HMatchups({ leagueId, userTeam, isAdmin, user }) {
  const { addNotification } = useNotification();
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [gameweeks, setGameweeks] = useState([]);
  const [matchups, setMatchups] = useState([]);
  const [expandedMatchups, setExpandedMatchups] = useState({});
  const [lastImportTime, setLastImportTime] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [allGameweeks, setAllGameweeks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchGameweeks();
  }, []);

  useEffect(() => {
    if (allGameweeks.length > 0) {
      const now = Date.now();
      const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
      const fiveDaysInMs = 5 * 24 * 60 * 60 * 1000;

      const currentGW = allGameweeks.find(gw => {
        const timeSinceDeadline = now - gw.deadline_time_epoch * 1000;
        const timeUntilDeadline = gw.deadline_time_epoch * 1000 - now;
        return timeSinceDeadline > 0 && timeSinceDeadline <= threeDaysInMs || 
               (timeUntilDeadline > 0 && timeUntilDeadline <= fiveDaysInMs);
      }) || allGameweeks[allGameweeks.length - 1];

      setSelectedWeek(currentGW.id);

      if (!isAdmin) {
        const filteredGameweeks = allGameweeks.filter(gw => 
          gw.deadline_time_epoch * 1000 <= now + fiveDaysInMs
        );
        setGameweeks(filteredGameweeks);
      } else {
        setGameweeks(allGameweeks);
      }
    }
  }, [allGameweeks, isAdmin]);

  useEffect(() => {
    if (selectedWeek) {
      fetchMatchups();
    }
  }, [selectedWeek]);

  const fetchGameweeks = async () => {
    try {
      const response = await fetch('/api/gameweeks', {
        headers: {
          'Authorization': localStorage.getItem('token'),
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setAllGameweeks(data.gameweeks);
    } catch (error) {
      console.error('Error fetching gameweeks:', error);
    }
  };

  const fetchMatchups = async () => {
    try {
      const response = await fetch(`/api/h2h-matchups?leagueId=${leagueId}&week=${selectedWeek}`, {
        headers: {
          'Authorization': localStorage.getItem('token'),
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setMatchups(data.matchups || []);
      setLastImportTime(data.last_import_time || null);
      // Initialize expanded state for new matchups
      setExpandedMatchups(prev => {
        const newState = {...prev};
        data.matchups.forEach((_, index) => {
          if (!(index in newState)) {
            newState[index] = false;
          }
        });
        return newState;
      });
    } catch (error) {
      console.error('Error fetching matchups:', error);
      setMatchups([]);
      setLastImportTime(null);
    }
  };

  const toggleMatchup = (index) => {
    setExpandedMatchups(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const toggleAllDetails = () => {
    const allExpanded = Object.values(expandedMatchups).every(v => v);
    setExpandedMatchups(
      matchups.reduce((acc, _, index) => {
        acc[index] = !allExpanded;
        return acc;
      }, {})
    );
  };

  const handleUpdateScores = async () => {
    if (!user.is_admin) {
      addNotification('Only admins can update scores', 'error');
      return;
    }

    setIsUpdating(true);
    try {
      const response = await fetch('/api/trigger-player-points-update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token'),
        },
        body: JSON.stringify({ leagueId, week: selectedWeek }),
      });

      if (!response.ok) {
        throw new Error('Failed to update H2H results');
      }

      const data = await response.json();
      addNotification(data.message || 'H2H results updated successfully', 'success');
      // Refetch matchups after updating
      await fetchMatchups();
    } catch (error) {
      console.error('Error updating H2H results:', error);
      addNotification(error.message || 'Failed to update H2H results. Please try again.', 'error');
    } finally {
      setIsUpdating(false);
    }
  };

  const filteredMatchups = useMemo(() => {
    if (!searchQuery.trim()) return matchups;

    const query = searchQuery.toLowerCase().trim();
    return matchups.filter(matchup => {
      const players = [...matchup.home_team.players, ...matchup.away_team.players];
      return players.some(player => 
        player.web_name.toLowerCase().includes(query) ||
        player.first_name.toLowerCase().includes(query) ||
        player.second_name.toLowerCase().includes(query) ||
        player.team_name.toLowerCase().includes(query) ||
        matchup.home_team.name.toLowerCase().includes(query) ||
        matchup.away_team.name.toLowerCase().includes(query)
      );
    });
  }, [matchups, searchQuery]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const highlightSearchedPlayer = (player) => {
    if (!searchQuery.trim()) return false;
    
    const query = searchQuery.toLowerCase().trim();
    return player.web_name.toLowerCase().includes(query) ||
           player.first_name.toLowerCase().includes(query) ||
           player.second_name.toLowerCase().includes(query) ||
           player.team_name.toLowerCase().includes(query);
  };

  const renderTeamPlayers = (players) => {
    return (
      <ul className="list-none">
        {players.map((player) => (
          <li
            key={player.player_id}
            className={`mb-2 flex items-center bg-white p-2 rounded shadow cursor-pointer hover:bg-gray-100 ${
              highlightSearchedPlayer(player) ? 'border-2 border-yellow-400' : ''
            }`}
            onClick={() => setSelectedPlayer(player.player_id)}
          >
            <div className="w-12 h-12 rounded-full flex items-center justify-center mr-2 hidden sm:flex">
              <img
                src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo ? player.photo.replace('.jpg', '.png') : 'missing.png'}`}
                alt={player.web_name}
                className="w-10 h-10 rounded-full object-cover"
              />
            </div>
            <div className="flex-grow">
              <span className="font-semibold">{player.web_name}</span>
              <span className="ml-2 text-sm text-gray-600 hidden sm:inline">({player.position})</span>
            </div>
            <div className="text-sm font-bold mr-2">{player.points} pts</div>
            {player.point_delta !== 0 && (
              <div className={`text-sm font-bold ${player.point_delta > 0 ? 'text-green-600' : 'text-red-600'}`}>
                {player.point_delta > 0 ? '+' : ''}{player.point_delta}
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const renderTeamName = (team, isWinner, isDraw) => (
    <div className={`team flex items-center ${
      isWinner ? 'text-green-600 font-bold' : 
      isDraw ? 'text-yellow-600 font-semibold' : ''
    }`}>
      <img src={`/${team.user_name.toLowerCase()}.png`} alt={team.name} className="w-8 h-8 mr-2 rounded-full" />
      <span className="team-name mr-2 hidden sm:inline">{team.name}</span>
      <span className="team-score ml-auto">{team.score}</span>
    </div>
  );

  return (
    <div className="h2h-matchups">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">
          H2H Matchups
        </h2>
        <div className="flex flex-col items-end">
          {lastImportTime && (
            <span className="text-sm text-gray-600 mb-2">
              Last updated: {moment.utc(lastImportTime).fromNow()}
            </span>
          )}
          <div className="flex">
            <button
              onClick={toggleAllDetails}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            >
              {Object.values(expandedMatchups).every(v => v) ? 'Hide All' : 'Show All'}
            </button>
            {user.is_admin && (
              <button
                onClick={handleUpdateScores}
                disabled={isUpdating}
                className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isUpdating ? 'Updating...' : 'Update Scores'}
              </button>
            )}
          </div>
        </div>
      </div>
      
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search players, teams..."
          value={searchQuery}
          onChange={handleSearch}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="week-selector mb-6">
        <select 
          id="week-select"
          value={selectedWeek || ''}
          onChange={(e) => setSelectedWeek(Number(e.target.value))}
          className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {gameweeks.map((gw) => (
            <option key={gw.id} value={gw.id}>
              {gw.name}
            </option>
          ))}
        </select>
      </div>

      {filteredMatchups.length > 0 ? (
        <div className="matchups space-y-4">
          {filteredMatchups.map((matchup, index) => (
            <div key={index} className={`matchup bg-gray-100 shadow-md rounded-lg p-4 ${
              userTeam.id === matchup.home_team.id || userTeam.id === matchup.away_team.id
                ? 'border-2 border-blue-500'
                : ''
            }`}>
              <div 
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleMatchup(index)}
              >
                {renderTeamName(matchup.home_team, matchup.winner_id === matchup.home_team.id, matchup.is_draw)}
                <div className="vs mx-4 text-gray-500">
                  {matchup.is_draw ? 'Draw' : 'vs'}
                </div>
                {renderTeamName(matchup.away_team, matchup.winner_id === matchup.away_team.id, matchup.is_draw)}
                <span className="text-blue-500 ml-4">
                  {expandedMatchups[index] ? '▲' : '▼'}
                </span>
              </div>
              {(expandedMatchups[index] || searchQuery) && (
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div>
                    {renderTeamPlayers(matchup.home_team.players)}
                  </div>
                  <div>
                    {renderTeamPlayers(matchup.away_team.players)}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>No matchups found for the current search.</p>
      )}

      {selectedPlayer && (
        <PlayerDetails 
          playerId={selectedPlayer} 
          onClose={() => setSelectedPlayer(null)}
        />
      )}
    </div>
  );
}

export default H2HMatchups;
