import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Search, Play, Pause, RotateCcw, Settings, X, Menu, User } from 'lucide-react';
import io from 'socket.io-client';

const removeAccents = (str) => {
  return str.normalize("NFKD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\u0000-\u007E]/g, char => {
      switch (char) {
        case 'æ': return 'ae';
        case 'œ': return 'oe';
        case 'ø': return 'o';
        case 'Æ': return 'AE';
        case 'Œ': return 'OE';
        case 'Ø': return 'O';
        default: return '';
      }
    });
};

const formations = ['4-4-2', '4-3-3', '3-4-3', '3-5-2', '5-3-2', '5-4-1', '4-5-1'];

const FantasyDraftApp = () => {
  const [user, setUser] = useState(null);
  const [loginError, setLoginError] = useState('');
  const [participants, setParticipants] = useState([]);
  const [isDraftStarted, setIsDraftStarted] = useState(false);
  const [isFirstPicker, setIsFirstPicker] = useState(false);
  const [showStartDraftButton, setShowStartDraftButton] = useState(false);
  const [socket, setSocket] = useState(null);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [pickedPlayers, setPickedPlayers] = useState({});
  const [roundPicks, setRoundPicks] = useState([]);
  const [currentPicker, setCurrentPicker] = useState(null);
  const [round, setRound] = useState(1);
  const [lastPick, setLastPick] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [positionFilter, setPositionFilter] = useState('All');
  const [teamFilter, setTeamFilter] = useState('All');
  const [viewMode, setViewMode] = useState('draft'); // 'draft', 'round', or 'team'
  const [selectedParticipant, setSelectedParticipant] = useState(participants[0]);
  const [selectedFormation, setSelectedFormation] = useState(formations[0]);
  const [timer, setTimer] = useState(60);
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [isTimerPaused, setIsTimerPaused] = useState(true);
  const [selectedPlayerToSwap, setSelectedPlayerToSwap] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobilePlayerModalOpen, setIsMobilePlayerModalOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  // Create refs for each participant's section
  const participantRefs = useRef({});

  useEffect(() => {
    // Initialize refs for each participant
    participants.forEach(participant => {
      participantRefs.current[participant.id] = React.createRef();
    });
  }, [participants]);

  useEffect(() => {
    if (participants.length > 0 && !selectedParticipant) {
      setSelectedParticipant(participants[0].id.toString());
    }
  }, [participants, selectedParticipant]);

  const [draftState, setDraftState] = useState({
    id: null,
    leagueId: null,
    currentPicker: null,
    round: 1,
    lastPick: null,
    status: 'NOT_STARTED'
  });

  // Add this new function to handle starting the draft
  const handleStartDraft = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;
  
    try {
      const response = await fetch('/api/start-draft', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      });
      const data = await response.json();
      if (data.success) {
        // The server will broadcast the updated state, so we don't need to update it here
        console.log('Draft started successfully');
      } else {
        console.error('Failed to start draft:', data.message);
      }
    } catch (error) {
      console.error('Error starting draft:', error);
    }
  };

  useEffect(() => {
    const checkDraftStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;
  
      try {
        const response = await fetch('/api/draft-state', {
          headers: { 'Authorization': token }
        });
        const data = await response.json();
        if (data.success) {
          setDraftState({
            currentPicker: data.currentPicker,
            round: data.round,
            lastPick: data.lastPick,
            status: data.status
          });
          setShowStartDraftButton(user?.is_admin && data.status === 'NOT_STARTED');
        }
      } catch (error) {
        console.error('Error checking draft status:', error);
      }
    };
  
    checkDraftStatus();
  }, [user]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Verify token and set user
      fetch('/api/verify-token', {
        headers: {
          'Authorization': token
        }
      })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setUser(data.user);
          fetchParticipants();
        } else {
          localStorage.removeItem('token');
        }
      })
      .catch(err => console.error('Error verifying token:', err));
    }
  }, []);

  const handleLogin = (username, password) => {
    fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    })
    .then(res => res.json())
    .then(data => {
      if (data.success) {
        localStorage.setItem('token', data.token);
        setUser(data.user);
        setLoginError('');
        fetchParticipants(); // Fetch participants after successful login
      } else {
        setLoginError(data.message);
      }
    })
    .catch(err => {
      console.error('Login error:', err);
      setLoginError('An error occurred during login');
    });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  useEffect(() => {
    // Scroll to the current participant's section when it changes
    if (viewMode === 'draft' && currentPicker && participantRefs.current[currentPicker]) {
      const currentPickerElement = participantRefs.current[currentPicker].current;
      if (currentPickerElement) {
        currentPickerElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [currentPicker, viewMode, participantRefs]);

  const fetchParticipants = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) return;
  
    fetch('/api/participants', {
      headers: {
        'Authorization': token
      }
    })
    .then(res => res.json())
    .then(data => {
      if (data.success) {
        setParticipants(data.participants);
      } else {
        console.error('Failed to fetch participants:', data.message);
      }
    })
    .catch(error => console.error('Error fetching participants:', error));
  }, []);

  const fetchInitialData = useCallback(() => {
    const token = localStorage.getItem('token');
    if (!token) return;
    const leagueId = 1;
  
    Promise.all([
      fetch('/api/players', { headers: { 'Authorization': token } }).then(res => res.json()),
      fetch(`/api/picked-players?league_id=${leagueId}`, { headers: { 'Authorization': token } }).then(res => res.json()),
      fetch(`/api/draft-state?league_id=${leagueId}`, { headers: { 'Authorization': token } }).then(res => res.json())
    ])
      .then(([playersData, pickedPlayersData, draftStateData]) => {
        // Set available players
        setAvailablePlayers(playersData.availablePlayers);
  
        // Set available teams
        setAvailableTeams(playersData.teams);
  
        // Set picked players
        const initialPickedPlayers = {};
        Object.entries(pickedPlayersData.pickedPlayers).forEach(([participantId, picks]) => {
          initialPickedPlayers[participantId] = picks;
        });
        setPickedPlayers(initialPickedPlayers);
  
        // Set round picks
        const allPicks = Object.values(pickedPlayersData.pickedPlayers).flat();
        const sortedPicks = allPicks.sort((a, b) => a.pick_number - b.pick_number);
        const formattedRoundPicks = sortedPicks.map(pick => ({
          round: pick.round,
          participant: participants.find(p => p.id === pick.participant_id),
          player: pick
        }));
        setRoundPicks(formattedRoundPicks);
  
        // Set current participant, round, and last pick from draft state
        setCurrentPicker(draftStateData.currentPicker.id);
        setRound(draftStateData.round);
        setLastPick(draftStateData.lastPick);

        // Set selectedParticipant to the current picker if available, otherwise to the first participant with picks
        if (draftStateData.currentPicker && draftStateData.currentPicker.id) {
          setSelectedParticipant(draftStateData.currentPicker.id.toString());
        } else {
          const firstParticipantWithPicks = Object.keys(initialPickedPlayers)[0];
          if (firstParticipantWithPicks) {
            setSelectedParticipant(firstParticipantWithPicks);
          }
        }
      })
      .catch(error => console.error('Error fetching initial data:', error));
  }, [participants]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    const newSocket = io(`http://localhost:${process.env.PORT || 3001}`);
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (!socket) return;
  
    const handleDraftStateUpdate = (draftState) => {
      setDraftState(draftState);
      setIsDraftStarted(draftState.status === 'IN_PROGRESS');
      setShowStartDraftButton(user?.is_admin && draftState.status === 'NOT_STARTED');
      setIsFirstPicker(draftState.isFirstPicker);

      // Check if the picker has changed
      if (draftState.currentPicker.id !== currentPicker) {
        setCurrentPicker(draftState.currentPicker.id);
        // Reset and start the timer
        setTimer(60);
        setIsTimerExpired(false);
        setIsTimerPaused(false);
      }

      setRound(draftState.round);
      setLastPick(draftState.lastPick);

      // Update selectedParticipant to the current picker
      setSelectedParticipant(draftState.currentPicker?.id?.toString());
      
      if (draftState.lastPick) {
        setPickedPlayers(prevPickedPlayers => {
          const newPickedPlayers = {...prevPickedPlayers};
          const participant = draftState.lastPick.participant;
          if (!newPickedPlayers[participant.id]) {
            newPickedPlayers[participant.id] = [];
          }
          // Check if the player is already in the list
          const playerAlreadyPicked = newPickedPlayers[participant.id].some(
            player => player.id === draftState.lastPick.player.id
          );
          if (!playerAlreadyPicked) {
            newPickedPlayers[participant.id] = [...newPickedPlayers[participant.id], draftState.lastPick.player];
          }
          return newPickedPlayers;
        });
  
        setAvailablePlayers(prevPlayers => 
          prevPlayers.filter(p => p.id !== draftState.lastPick.player.id)
        );
  
        setRoundPicks(prevPicks => {
          // Check if the pick is already in the list
          const pickAlreadyExists = prevPicks.some(
            pick => pick.player.id === draftState.lastPick.player.id &&
                    pick.round === draftState.lastPick.round &&
                    pick.participant === draftState.lastPick.participant
          );
          if (!pickAlreadyExists) {
            return [
              ...prevPicks,
              {
                round: draftState.lastPick.round,
                participant: draftState.lastPick.participant,
                player: draftState.lastPick.player
              }
            ];
          }
          return prevPicks;
        });
      }
    };

    const handlePickCanceled = (data) => {
      const token = localStorage.getItem('token');
      if (!token) return;

      if (data.success) {
        const newDraftState = data.newState;
        
        const leagueId = newDraftState.leagueId;
    
        // Fetch updated picked players from the API
        fetch(`/api/picked-players?league_id=${leagueId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
        })
          .then(response => response.json())
          .then(data => {
            const initialPickedPlayers = {};
            participants.forEach(participant => {
              initialPickedPlayers[participant.id] = data.pickedPlayers[participant.id] || [];
            });
            setPickedPlayers(initialPickedPlayers);
          })
          .catch(error => console.error('Error fetching picked players:', error));
    
        // Fetch updated available players from the API
        fetch('/api/players', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          }
        })
          .then(response => response.json())
          .then(data => {
            setAvailablePlayers(data.availablePlayers);
          })
          .catch(error => console.error('Error fetching available players:', error));
    
        // Remove the canceled pick from roundPicks
        setRoundPicks(prevPicks => prevPicks.filter(pick => pick.player.id !== data.canceledPlayerId));
    
        // Update lastPick
        if (newDraftState.lastPickId) {
          fetch(`/api/picks/${newDraftState.lastPickId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token
            }
          })
            .then(response => response.json())
            .then(lastPickData => {
              setLastPick(lastPickData);
            })
            .catch(error => console.error('Error fetching last pick:', error));
        } else {
          setLastPick(null);
        }
    
        setTimer(60);
        setIsTimerExpired(false);
        setIsTimerPaused(true);
      } else {
        console.error('Failed to cancel pick:', data.message);
      }
    };

    const handleDraftStarted = () => {
      console.log('Draft has started!');
      setIsDraftStarted(true);
      setShowStartDraftButton(false);
    };

    socket.on('draftStateUpdate', handleDraftStateUpdate);
    socket.on('draftStarted', handleDraftStarted);
    socket.on('pickCanceled', handlePickCanceled);
    socket.on('draftReset', handleDraftReset);
  
    return () => {
      socket.off('draftStateUpdate', handleDraftStateUpdate);
      socket.off('pickCanceled', handlePickCanceled);
      socket.off('draftStarted', handleDraftStarted);
      socket.off('draftReset', handleDraftReset);
    };
  }, [socket, participants, currentPicker, round, lastPick]);

  useEffect(() => {
    if (!socket || !user) return;
  
    // Set the user information on the socket connection
    socket.emit('setUser', { id: user.id, is_admin: user.is_admin });
  
    const handleTimerUpdate = ({ timer: serverTimer, isTimerPaused: serverIsTimerPaused }) => {
      setTimer(serverTimer);
      setIsTimerPaused(serverIsTimerPaused);
      setIsTimerExpired(serverTimer <= 0);
    };
  
    const handleError = (error) => {
      console.error('Socket error:', error.message);
      // You can add more error handling here, such as displaying an error message to the user
    };
  
    socket.on('timerUpdate', handleTimerUpdate);
    socket.on('error', handleError);
  
    return () => {
      socket.off('timerUpdate', handleTimerUpdate);
      socket.off('error', handleError);
    };
  }, [socket, user]);
  
  const toggleTimer = () => {
    if (user?.is_admin) {
      socket.emit('toggleTimer');
    } else {
      console.error('Only admins can control the timer');
      // You can add a user-facing error message here
    }
  };
  
  const restartTimer = () => {
    if (user?.is_admin) {
      socket.emit('restartTimer');
    } else {
      console.error('Only admins can restart the timer');
      // You can add a user-facing error message here
    }
  };

  const handlePlayerPick = (player) => {
    if (!user) return;
  
    const token = localStorage.getItem('token');
    fetch('/api/picks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        playerId: player.id,
        userId: user.is_admin && currentPicker !== user.id ? currentPicker : user.id,
        draftStateId: 1,
        leagueId: 1,
        round: round
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        console.log('Pick successful');
        // The server will broadcast the updated state, so we don't need to update it here
      } else {
        console.error('Failed to add pick:', data.message);
      }
    })
    .catch(error => console.error('Error adding pick:', error));
  };

  const handleCancelPick = () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    if (lastPick) {
      fetch(`/api/picks/${lastPick.player.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // The server will broadcast the updated state, so we don't need to update it here
          setShowConfirmation(null);
        } else {
          console.error('Failed to cancel pick:', data.message);
        }
      })
      .catch(error => {
        console.error('Error cancelling pick:', error);
      });
    }
  };

  const handleSkipPicker = () => {
    if (user.is_admin) {
      const token = localStorage.getItem('token');
      if (!token) return;

      fetch('/api/skip-picker', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({ adminName: user.first_name }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Picker skipped successfully');
          // The server will broadcast the updated state, so we don't need to update it here
        } else {
          console.error('Failed to skip picker:', data.message);
        }
      })
      .catch(error => console.error('Error skipping picker:', error));
    }
  };

  const handlePreviousPicker = () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    if (isFirstPicker) return;

    fetch('/api/previous-picker', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        console.log('Moved to previous pick successfully');
        // The server will broadcast the updated state, so we don't need to update it here
        setIsSettingsOpen(false);
      } else {
        console.error('Failed to go to previous pick:', data.message);
      }
    })
    .catch(error => console.error('Error going to previous pick:', error));
  };

  const handleDraftReset = (newDraftState) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    const leagueId = newDraftState.leagueId;

    setDraftState(newDraftState);
    setIsDraftStarted(newDraftState.status === 'IN_PROGRESS');
    setShowStartDraftButton(user?.is_admin && newDraftState.status === 'NOT_STARTED');

    fetch(`/api/picked-players?league_id=${leagueId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    })
      .then(response => response.json())
      .then(data => {
        const initialPickedPlayers = {};
        participants.forEach(participant => {
          initialPickedPlayers[participant.id] = data.pickedPlayers[participant.id] || [];
        });
        setPickedPlayers(initialPickedPlayers);
      })
      .catch(error => console.error('Error fetching picked players:', error));

    // Fetch updated available players from the API
    fetch('/api/players', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
      .then(response => response.json())
      .then(data => {
        setAvailablePlayers(data.availablePlayers);
      })
      .catch(error => console.error('Error fetching available players:', error));

    setTimer(60);
    setIsTimerExpired(false);
    setIsTimerPaused(true);

  };

  const resetDraft = () => {
    setShowConfirmation('resetDraft');
  };

  const confirmAction = () => {
    if (showConfirmation === 'cancelPick') {
      if (lastPick) {
        const newAvailablePlayers = [...availablePlayers, lastPick.player].sort((a, b) => b.total_points - a.total_points);
        const newPickedPlayers = {
          ...pickedPlayers,
          [lastPick.participant]: pickedPlayers[lastPick.participant].filter(p => p.id !== lastPick.player.id)
        };
        const newRoundPicks = roundPicks.slice(0, -1);
        
        // Calculate the correct previous participant
        let newcurrentPicker;
        if (lastPick.round === 1 && lastPick.currentPicker === 0) {
          // For the first pick of Round 1, stay on the first participant
          newcurrentPicker = participants.find(p => p.id === lastPick.participant).id;
        } else {
          // For all other cases, go back to the previous participant
          newcurrentPicker = (lastPick.currentPicker - 1 + participants.length) % participants.length;
        }
        
        const newRound = newRoundPicks.length > 0 
          ? newRoundPicks[newRoundPicks.length - 1].round 
          : 1;

        setAvailablePlayers(newAvailablePlayers);
        setPickedPlayers(newPickedPlayers);
        setRoundPicks(newRoundPicks);
        setCurrentPicker(newcurrentPicker);
        setRound(newRound);
        
        const previousPick = newRoundPicks[newRoundPicks.length - 1];
        if (previousPick) {
          setLastPick({
            player: previousPick.player,
            participant: previousPick.participant,
            currentPicker: newcurrentPicker,
            round: previousPick.round
          });
        } else {
          setLastPick(null);
        }
        setTimer(60);
        setIsTimerExpired(false);

        // Update localStorage
        const dataToStore = {
          availablePlayers: newAvailablePlayers,
          availableTeams,
          pickedPlayers: newPickedPlayers,
          roundPicks: newRoundPicks,
          currentPicker: newcurrentPicker,
          round: newRound,
          lastPick: previousPick || null
        };
        localStorage.setItem('fantasyDraftData', JSON.stringify(dataToStore));
      }
    } else if (showConfirmation === 'resetDraft') {
      const token = localStorage.getItem('token');
      if (!token) return;
      fetch('/api/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Draft reset successfully');
          // The server will broadcast the updated state, so we don't need to update it here
          setShowConfirmation(null);
        } else {
          console.error('Failed to reset draft:', data.message);
        }
      })
    }
    setShowConfirmation(null);
    setIsSettingsOpen(false);
  };

  const cancelAction = () => {
    setShowConfirmation(null);
  };

  const filteredPlayers = availablePlayers.filter(player => {
    const searchTerms = removeAccents(searchQuery.toLowerCase()).split(' ');
    const playerFullName = removeAccents(`${player.first_name} ${player.second_name} ${player.web_name}`.toLowerCase());
    const teamName = removeAccents(player.club_name.toLowerCase());
    const matchesSearch = searchTerms.every(term => 
      playerFullName.includes(term) || teamName.includes(term)
    );
    const matchesPosition = positionFilter === 'All' || player.position === positionFilter;
    const matchesTeam = teamFilter === 'All' || player.club_name === teamFilter;
    return matchesSearch && matchesPosition && matchesTeam;
  });

  const getPlayerCounts = (players) => {
    const counts = {
      total: 0,
      GK: 0,
      DEF: 0,
      MID: 0,
      FWD: 0
    };
    if (players && Array.isArray(players)) {
      counts.total = players.length;
      players.forEach(player => {
        counts[player.position]++;
      });
    }
    return counts;
  };

  const [draftViewMode, setDraftViewMode] = useState('grid');

  const toggleDraftViewMode = () => {
    setDraftViewMode(prevMode => prevMode === 'grid' ? 'list' : 'grid');
  };

  const [roundViewMode, setRoundViewMode] = useState('table');

  const toggleRoundViewMode = () => {
    setRoundViewMode(prevMode => prevMode === 'grid' ? 'list' : 'grid');
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md">
          <h2 className="text-2xl font-bold mb-4">Login</h2>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleLogin(e.target.username.value, e.target.password.value);
          }}>
            <input
              type="text"
              name="username"
              placeholder="Username"
              className="w-full p-2 mb-4 border rounded"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="w-full p-2 mb-4 border rounded"
              required
            />
            <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">
              Login
            </button>
          </form>
          {loginError && <p className="text-red-500 mt-2">{loginError}</p>}
        </div>
      </div>
    );
  }

  const renderDraftView = () => (
    <div className="w-3/4 p-4 overflow-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Picked Players</h2>
        <button
          onClick={toggleDraftViewMode}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 text-sm font-semibold py-1 px-2 rounded"
        >
          {draftViewMode === 'grid' ? 'List View' : 'Grid View'}
        </button>
      </div>
      {participants.map((participant) => {
        const participantPicks = pickedPlayers[participant.id] || [];
        const playerCounts = getPlayerCounts(participantPicks);
        const isCurrentPicker = participant.id === currentPicker;
        return (
          <div 
            key={participant.id} 
            className={`mb-8 bg-white rounded-lg shadow-md p-4 ${draftState.status === 'IN_PROGRESS' && isCurrentPicker ? 'ring-2 ring-blue-500' : ''}`} 
            ref={participantRefs.current[participant.id]}
          >
            <div className="flex items-center mb-4">
              <img
                src={`/${participant.first_name.toLowerCase()}.png`}
                alt={participant.first_name}
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <h3 className={`text-2xl font-semibold ${draftState.status === 'IN_PROGRESS' && isCurrentPicker ? 'text-blue-500' : ''}`}>
                  {participant.first_name}
                </h3>
                <p className="text-sm text-gray-600">
                  Total: {playerCounts.total} (GK: {playerCounts.GK}, DEF: {playerCounts.DEF}, MID: {playerCounts.MID}, FWD: {playerCounts.FWD})
                </p>
              </div>
            </div>
            {draftViewMode === 'grid' ? (
              <div className="grid grid-cols-5 gap-2">
                {participantPicks.map((player, index) => (
                  <div key={player.id} className={`flex flex-col items-center`}>
                    <img 
                      src={player.photo 
                        ? `https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo.replace('.jpg', '.png')}`
                        : `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${player.team_code}-110.png`
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${player.team_code}-110.png`;
                      }}
                      alt={player.web_name} 
                      className="w-12 h-12 object-cover rounded-full mb-1" 
                    />
                    <p className="text-xs text-center font-semibold">{player.web_name}</p>
                  </div>
                ))}
              </div>
            ) : (
              <ul className="list-none">
                {participantPicks.map((player) => (
                  <li key={player.id} className="mb-2 flex items-center">
                    <img 
                      src={player.photo 
                        ? `https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo.replace('.jpg', '.png')}`
                        : `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${player.team_code}-110.png`
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${player.team_code}-110.png`;
                      }}
                      alt={player.web_name} 
                      className="w-8 h-8 object-cover rounded-full mr-2" 
                    />
                    <span className="font-semibold">{player.web_name}</span> - {player.position} ({player.club_name})
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      })}
    </div>
  );

  const renderRoundView = () => {
    const maxRounds = Math.max(...roundPicks.map(pick => pick.round));
    const sortedParticipants = [...participants].sort((a, b) => {
      const firstPickA = roundPicks.find(pick => pick.participant.id === a.id);
      const firstPickB = roundPicks.find(pick => pick.participant.id === b.id);
      return (firstPickA?.pick_number || 0) - (firstPickB?.pick_number || 0);
    });
  
    return (
      <div className="w-3/4 p-4 overflow-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Picks by Round</h2>
          <select
            onChange={(e) => setRoundViewMode(e.target.value)}
            value={roundViewMode}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 text-sm font-semibold py-1 px-2 rounded"
          >
            <option value="table">Table View</option>
            <option value="grid">Grid View</option>
            <option value="list">List View</option>
          </select>
        </div>
  
        {roundViewMode === 'table' && (
          <div className="overflow-x-auto">
            <table className="w-full border-collapse bg-white shadow-md rounded-lg table-auto">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border p-2 sticky left-0 bg-gray-100 z-10">Round</th>
                  {sortedParticipants.map(participant => (
                    <th key={participant.id} className="border p-2 whitespace-nowrap">{participant.first_name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[...Array(maxRounds)].map((_, index) => {
                  const roundNumber = index + 1;
                  return (
                    <tr key={roundNumber} className="hover:bg-gray-50">
                      <td className="border p-2 font-bold sticky left-0 bg-white z-10">Round {roundNumber}</td>
                      {sortedParticipants.map(participant => {
                        const pick = roundPicks.find(p => p.round === roundNumber && p.participant.id === participant.id);
                        return (
                          <td key={participant.id} className="border p-2 text-center">
                            {pick ? (
                              <div className="flex items-center justify-center">
                                <span className="truncate">{pick.player.web_name}</span>
                              </div>
                            ) : '-'}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
  
        {roundViewMode === 'grid' && (
          <div>
            {[...Array(maxRounds)].map((_, index) => {
              const roundNumber = index + 1;
              const roundPicksFiltered = roundPicks.filter(pick => pick.round === roundNumber);
              return (
                <div key={roundNumber} className="mb-8">
                  <h3 className="text-xl font-semibold mb-4">Round {roundNumber}</h3>
                  <div className="grid grid-cols-3 gap-2">
                    {roundPicksFiltered.map((pick, pickIndex) => (
                      <div key={pickIndex} className="bg-white p-2 rounded shadow">
                        <img
                          src={pick.player.photo 
                            ? `https://resources.premierleague.com/premierleague/photos/players/250x250/p${pick.player.photo.replace('.jpg', '.png')}`
                            : `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${pick.player.team_code}-110.png`
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${pick.player.team_code}-110.png`;
                          }}
                          alt={pick.player.web_name}
                          className="w-16 h-16 object-cover rounded-full mx-auto"
                        />
                        <p className="text-center mt-2">{pick.player.web_name}</p>
                        <p className="text-center text-sm text-gray-500">{pick.player.club_name} - {pick.player.position}</p>
                        <p className="text-center text-sm font-semibold">{pick.participant.first_name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
  
        {roundViewMode === 'list' && (
          <div>
            {[...Array(maxRounds)].map((_, index) => {
              const roundNumber = index + 1;
              const roundPicksFiltered = roundPicks.filter(pick => pick.round === roundNumber);
              return (
                <div key={roundNumber} className="mb-8">
                  <h3 className="text-xl font-semibold mb-4">Round {roundNumber}</h3>
                  <ul className="list-none">
                    {roundPicksFiltered.map((pick, pickIndex) => (
                      <li key={pickIndex} className="mb-2 flex items-center bg-white p-2 rounded shadow">
                        <img 
                          src={pick.player.photo 
                            ? `https://resources.premierleague.com/premierleague/photos/players/250x250/p${pick.player.photo.replace('.jpg', '.png')}`
                            : `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${pick.player.team_code}-110.png`
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${pick.player.team_code}-110.png`;
                          }}
                          alt={pick.player.web_name} 
                          className="w-8 h-8 object-cover rounded-full mr-2" 
                        />
                        <div>
                          <span className="font-semibold">{pick.player.web_name}</span> - {pick.player.position} ({pick.player.club_name})
                          <p className="text-sm text-gray-600">Picked by: {pick.participant.first_name}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderTeamView = () => {
    if (!selectedParticipant) {
      return <div>Loading...</div>;
    }
    const teamPlayers = pickedPlayers[selectedParticipant] || [];
    const [def, mid, fwd] = selectedFormation.split('-').map(Number);
    const formation = [1, def, mid, fwd];
    const positions = ['GK', 'DEF', 'MID', 'FWD'];
  
    const lineUp = positions.flatMap((pos, index) => {
      const posPlayers = teamPlayers.filter(p => p.position === pos);
      return Array(formation[index]).fill().map((_, i) => posPlayers[i] || { position: pos, web_name: 'Empty' });
    });
  
    const benchPlayers = teamPlayers.filter(player => !lineUp.find(p => p.id === player.id));
  
    const handleSwap = (benchPlayer) => {
      if (selectedPlayerToSwap) {
        const updatedLineUp = lineUp.map(player => 
          player.id === selectedPlayerToSwap.id ? benchPlayer : player
        );
        const updatedTeamPlayers = [
          ...updatedLineUp.filter(player => player.id),
          ...benchPlayers.filter(player => player.id !== benchPlayer.id).concat(selectedPlayerToSwap)
        ];
        setPickedPlayers(prev => ({
          ...prev,
          [selectedParticipant]: updatedTeamPlayers
        }));
        setSelectedPlayerToSwap(null);
      }
    };
  
    return (
      <div className="w-3/4 p-4 overflow-auto">
        <h2 className="text-2xl font-bold mb-4">Team View</h2>
        <div className="mb-4">
          <select
            className="p-2 border rounded mr-2"
            value={selectedParticipant}
            onChange={(e) => setSelectedParticipant(e.target.value)}
          >
            {participants.map(participant => (
              <option key={participant.id} value={participant.id}>
                {participant.first_name}
              </option>
            ))}
          </select>
          <select
            className="p-2 border rounded"
            value={selectedFormation}
            onChange={(e) => setSelectedFormation(e.target.value)}
          >
            {formations.map(formation => (
              <option key={formation} value={formation}>{formation}</option>
            ))}
          </select>
        </div>
        <div className="bg-green-500 p-4 rounded-lg mb-4">
          {positions.map((pos, posIndex) => (
            <div key={pos} className={`flex justify-around mb-4 ${pos === 'GK' ? 'mt-20' : ''}`}>
              {lineUp.filter(p => p.position === pos).map((player, playerIndex) => (
                <div key={playerIndex} className="text-center">
                  <div 
                    className={`bg-white rounded-full w-16 h-16 flex items-center justify-center mb-2 mx-auto cursor-pointer ${selectedPlayerToSwap?.id === player.id ? 'border-4 border-yellow-400' : ''}`}
                    onClick={() => setSelectedPlayerToSwap(player.id ? player : null)}
                  >
                    {player.photo ? (
                      <img
                        src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo.replace('.jpg', '.png')}`}
                        alt={player.web_name}
                        className="w-14 h-14 rounded-full object-cover"
                      />
                    ) : (
                      <span className="text-2xl">?</span>
                    )}
                  </div>
                  <p className="text-white text-sm">{player.web_name}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="mt-4">
          <h3 className="text-xl font-bold mb-2">Bench</h3>
          <div className="grid grid-cols-4 gap-2">
            {benchPlayers.map((player, index) => (
              <div 
                key={player.id} 
                className={`bg-gray-200 p-2 rounded text-center cursor-pointer ${selectedPlayerToSwap ? 'hover:bg-gray-300' : ''}`}
                onClick={() => selectedPlayerToSwap && handleSwap(player)}
              >
                <img
                  src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo.replace('.jpg', '.png')}`}
                  alt={player.web_name}
                  className="w-12 h-12 rounded-full object-cover mx-auto mb-1"
                />
                <p className="text-sm font-semibold">{player.web_name}</p>
                <p className="text-xs text-gray-600">{player.position}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const getTimerColor = () => {
    if (timer <= 10) return 'text-red-500';
    if (timer <= 30) return 'text-yellow-500';
    return 'text-green-500';
  };

  const renderMobileMenu = () => (
    <div className="lg:hidden">
      <button
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        className="fixed top-4 right-4 z-50 bg-blue-500 text-white p-2 rounded-full"
      >
        <Menu size={24} />
      </button>
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-40 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg w-4/5">
            <button
              onClick={() => setViewMode('draft')}
              className="block w-full text-left px-4 py-2 mb-2 rounded hover:bg-gray-100"
            >
              Draft View
            </button>
            <button
              onClick={() => setViewMode('round')}
              className="block w-full text-left px-4 py-2 mb-2 rounded hover:bg-gray-100"
            >
              By Round
            </button>
            <button
              onClick={() => setViewMode('team')}
              className="block w-full text-left px-4 py-2 mb-2 rounded hover:bg-gray-100"
            >
              By Team
            </button>
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="block w-full text-center px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const renderMobilePlayerModal = () => (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg w-4/5">
        <h3 className="text-xl font-bold mb-4">{selectedPlayer.web_name}</h3>
        <img 
          src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${selectedPlayer.photo.replace('.jpg', '.png')}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${selectedPlayer.team_code}-110.png`;
          }}
          alt={selectedPlayer.web_name} 
          className="w-32 h-32 object-cover rounded-full mx-auto mb-4" 
        />
        <p className="text-center mb-2">{selectedPlayer.first_name} {selectedPlayer.second_name}</p>
        <p className="text-center mb-4">{selectedPlayer.club_name} - {selectedPlayer.position}</p>
        <button
          onClick={() => {
            handlePlayerPick(selectedPlayer);
            setIsMobilePlayerModalOpen(false);
          }}
          className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-2"
        >
          Pick
        </button>
        <button
          onClick={() => setIsMobilePlayerModalOpen(false)}
          className="w-full bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
        >
          Cancel
        </button>
      </div>
    </div>
  );

  // Update the footer to include the claim participant button
  const renderFooter = () => (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex flex-wrap justify-between items-center">
      <p className="w-full sm:w-auto mb-2 sm:mb-0">
        {draftState.currentPicker && (
          <span>Current Picker: {draftState.currentPicker.first_name}</span>
        )}
      </p>
      <p className="w-full sm:w-auto mb-2 sm:mb-0">Round: {draftState.round}</p>
      <div className="flex items-center w-full sm:w-auto justify-center sm:justify-start">
        <span className={`text-2xl font-bold mr-2 ${getTimerColor()}`}>
          {timer}s
        </span>
        {user?.is_admin && (
          <>
            <button
              onClick={toggleTimer}
              className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600"
            >
              {isTimerPaused ? <Play size={20} /> : <Pause size={20} />}
            </button>
            <button
              onClick={restartTimer}
              className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 ml-2"
            >
              <RotateCcw size={20} />
            </button>
          </>
        )}
      </div>
      <div className="relative mt-2 sm:mt-0 flex items-center">
        {user ? (
          <div className="flex items-center mr-4">
            <img
              src={`/${user.first_name.toLowerCase()}.png`}
              alt={`${user.first_name}'s avatar`}
              className="w-8 h-8 rounded-full mr-2"
            />
            <span>{user.first_name}</span>
          </div>
        ) : (
          <User size={20} />
        )}
        {user && (
          <>
            <button
              onClick={() => setIsSettingsOpen(!isSettingsOpen)}
              className="bg-gray-600 text-white p-2 rounded-full hover:bg-gray-700"
            >
              <Settings size={20} />
            </button>
            {isSettingsOpen && (
              <div className="absolute bottom-full right-0 mb-2 bg-white text-gray-800 rounded shadow-lg w-48">
                {lastPick && user.is_admin && (
                  <button
                    onClick={handleCancelPick}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  >
                    Cancel Last Pick
                  </button>
                )}
                {user.is_admin && (
                  <>
                    <button
                      onClick={handleSkipPicker}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                    >
                      Skip to Next Picker
                    </button>
                    <button
                      onClick={handlePreviousPicker}
                      className={`block w-full text-left px-4 py-2 ${isFirstPicker ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-gray-100'}`}
                      disabled={isFirstPicker}
                    >
                      Go to Previous Picker
                    </button>
                    <button
                      onClick={resetDraft}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                    >
                      Reset Draft
                    </button>
                  </>
                )}
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gray-100">

      {renderMobileMenu()}

      {draftState.status === 'NOT_STARTED' && (
        <div className="fixed top-0 left-0 right-0 bg-yellow-500 text-white p-2 text-center">
          Waiting for the draft to start...
          {showStartDraftButton && (
            <button
              onClick={handleStartDraft}
              className="ml-4 bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-4 rounded"
            >
              Start Draft
            </button>
          )}
        </div>
      )}

      <div className="w-full lg:w-1/2 p-4 overflow-auto">
        <div className="mb-4 hidden lg:block">
          <button
            onClick={() => setViewMode('draft')}
            className={`mr-2 px-4 py-2 rounded ${viewMode === 'draft' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Draft View
          </button>
          <button
            onClick={() => setViewMode('round')}
            className={`mr-2 px-4 py-2 rounded ${viewMode === 'round' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            By Round
          </button>
          <button
            onClick={() => setViewMode('team')}
            className={`px-4 py-2 rounded ${viewMode === 'team' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            By Team
          </button>
        </div>
        {viewMode === 'draft' && renderDraftView()}
        {viewMode === 'round' && renderRoundView()}
        {viewMode === 'team' && renderTeamView()}
      </div>

      <div className="w-full lg:w-1/2 p-4 bg-white shadow-lg overflow-auto">
        <h2 className="text-2xl font-bold mb-4">Available Players</h2>
        <div className="mb-4 relative">
          <input
            type="text"
            placeholder="Search players or teams..."
            className="w-full p-2 pl-8 border rounded"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            autoComplete="off"
            name="player-search"
          />
          <Search className="absolute left-2 top-3 text-gray-400" size={20} />
        </div>
        <div className="mb-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
          <select
            className="p-2 border rounded w-full sm:w-auto"
            value={positionFilter}
            onChange={(e) => setPositionFilter(e.target.value)}
          >
            <option value="All">All Positions</option>
            <option value="GK">Goalkeeper</option>
            <option value="DEF">Defender</option>
            <option value="MID">Midfielder</option>
            <option value="FWD">Forward</option>
          </select>
          <select
            className="p-2 border rounded w-full sm:w-auto"
            value={teamFilter}
            onChange={(e) => setTeamFilter(e.target.value)}
          >
            <option value="All">All Teams</option>
            {availableTeams.map(team => (
              <option key={team.id} value={team.name}>{team.name}</option>
            ))}
          </select>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {filteredPlayers.map(player => (
            <div key={player.id} className="bg-gray-100 p-4 rounded shadow flex items-center">
              <img 
                src={player.photo 
                  ? `https://resources.premierleague.com/premierleague/photos/players/250x250/p${player.photo.replace('.jpg', '.png')}`
                  : `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${player.team_code}-110.png`
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${player.team_code}-110.png`;
                }}
                alt={player.web_name} 
                className="w-16 h-16 object-cover rounded-full mr-4" 
              />
              <div>
                <p className="font-semibold">{player.web_name}</p>
                <p className="text-sm text-gray-500">{player.first_name} {player.second_name}</p>
                <p className="text-sm text-gray-6000">{player.club_name} - {player.position}</p>
                {(draftState.status === 'IN_PROGRESS' && (user.id === currentPicker || user.is_admin)) ? (
                  <button
                    onClick={() => {
                      if (window.innerWidth < 1024) {
                        setSelectedPlayer(player);
                        setIsMobilePlayerModalOpen(true);
                      } else {
                        handlePlayerPick(player);
                      }
                    }}
                    className="mt-2 bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
                  >
                    Pick
                  </button>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>

      {renderFooter()}

      {/* Confirmation Dialog */}
      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-4/5 max-w-md">
            <h3 className="text-xl font-bold mb-4">
              {showConfirmation === 'cancelPick' ? 'Cancel Last Pick?' : 'Reset Draft?'}
            </h3>
            <p className="mb-6">
              {showConfirmation === 'cancelPick'
                ? 'Are you sure you want to cancel the last pick?'
                : 'Are you sure you want to reset the entire draft? This action cannot be undone.'}
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={cancelAction}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                No, Cancel
              </button>
              <button
                onClick={confirmAction}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Yes, Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {isMobilePlayerModalOpen && renderMobilePlayerModal()}
    </div>
  );
};

export default FantasyDraftApp;
