import React, { useState, useEffect } from 'react';
import { useNotification } from './contexts/NotificationContext';

function PointsTable({ data, isAdmin, user }) {
  const { addNotification } = useNotification();
  const [sortColumn, setSortColumn] = useState('rank');
  const [sortDirection, setSortDirection] = useState('asc');
  const [isUpdating, setIsUpdating] = useState(false);
  const [pointsData, setPointsData] = useState(data);

  const sortedData = [...pointsData].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const fetchPointsData = async () => {
    try {
      const token = localStorage.getItem('token');
      if(!token) return;

      const leagueId = 1; // You might want to make this dynamic

      const response = await fetch(`/api/points-table?leagueId=${leagueId}`, {
        headers: {
          'Authorization': token
        }
      });
      if (!response.ok) throw new Error('Failed to fetch points data');
      const data = await response.json();
      setPointsData(data.pointsTable);
    } catch (err) {
      console.error('Error fetching points data:', err);
      throw err;
    }
  };

  const triggerH2HUpdate = async () => {
    setIsUpdating(true);
    try {
      const response = await fetch('/api/trigger-h2h-update', {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('token'),
        },
      });
      const result = await response.json();
      if (result.success) {
        addNotification('Points updated successfully', 'success');
        fetchPointsData();
      } else {
        addNotification('Failed to trigger H2H results update', 'error');
      }
    } catch (error) {
      console.error('Error triggering H2H update:', error);
      addNotification('An error occurred while triggering the update', 'error');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">
          Points Table
        </h2>
        <div className="flex flex-col items-end">
          {user && user.is_admin && (
            <button
              onClick={triggerH2HUpdate}
              className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isUpdating}
            >
              {isUpdating ? 'Updating...' : 'Update Points'}
            </button>
          )}
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              {['Rank', 'Team', 'Total Points', 'Std Devs', 'Penalty'].map((header) => (
                <th
                  key={header}
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-200"
                  onClick={() => handleSort(header.toLowerCase().replace(' ', ''))}
                >
                  {header}
                  {sortColumn === header.toLowerCase().replace(' ', '') && (
                    <span className="ml-1">{sortDirection === 'asc' ? '▲' : '▼'}</span>
                  )}
                </th>
              ))}
              {Array.from({ length: 38 }, (_, i) => (
                <th key={i} className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Week {i + 1}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {sortedData.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="px-4 py-2 whitespace-nowrap">{row.rank}</td>
                <td className="px-4 py-2 whitespace-nowrap font-medium flex items-center">
                  {row.team}
                  {row.rank === 1 && <span className="ml-2">👑</span>}
                  {index === sortedData.length - 1 && (
                    <img src="sakho.png" alt="Sakho" className="ml-2 w-6 h-6 object-contain" />
                  )}
                </td>
                <td className="px-4 py-2 whitespace-nowrap">{row.total_points}</td>
                <td className="px-4 py-2 whitespace-nowrap">{row.stdDevs?.toFixed(2) || 'N/A'}</td>
                <td className="px-4 py-2 whitespace-nowrap">{row.penalty || 0}</td>
                {row.weekly_points.map((points, i) => (
                  <td key={i} className="px-4 py-2 whitespace-nowrap">
                    {points !== null ? points : '-'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PointsTable;
