import React, { useState, useEffect } from 'react';
import { useNotification } from './contexts/NotificationContext';

function H2HTable({ data, isAdmin, user }) {
  const { addNotification } = useNotification();
  const [sortColumn, setSortColumn] = useState('rank');
  const [sortDirection, setSortDirection] = useState('asc');
  const [isUpdating, setIsUpdating] = useState(false);
  const [pointsData, setPointsData] = useState(data);

  const fetchH2HData = async () => {
    try {
      const token = localStorage.getItem('token');
      if(!token) return;

      const leagueId = 1; // You might want to make this dynamic

      const response = await fetch(`/api/h2h-table?leagueId=${leagueId}`, {
        headers: {
          'Authorization': token
        }
      });
      if (!response.ok) throw new Error('Failed to fetch points data');
      const data = await response.json();
      setPointsData(data.h2hTable);
    } catch (err) {
      console.error('Error fetching points data:', err);
      throw err;
    }
  };

  const triggerH2HUpdate = async () => {
    setIsUpdating(true);
    try {
      const response = await fetch('/api/trigger-h2h-update', {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('token'),
        },
      });
      const result = await response.json();
      if (result.success) {
        addNotification('Points updated successfully', 'success');
        fetchH2HData();
      } else {
        addNotification('Failed to trigger H2H results update', 'error');
      }
    } catch (error) {
      console.error('Error triggering H2H update:', error);
      addNotification('An error occurred while triggering the update', 'error');
    } finally {
      setIsUpdating(false);
    }
  };
  return (
    <div>
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-2xl font-bold">
        H2H Table
      </h2>
      <div className="flex flex-col items-end">
        {user && user.is_admin && (
          <button
            onClick={triggerH2HUpdate}
            className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isUpdating}
          >
            {isUpdating ? 'Updating...' : 'Update H2H'}
          </button>
        )}
      </div>
    </div>
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            {['Rank', 'Team', 'H2H Pts', 'Win', 'Loss', 'Draw', 'Pts For', 'Against', '+/-'].map((header) => (
              <th key={header} className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.map((row, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{row.rank}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{row.team}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{row.h2h_points}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{row.wins}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{row.losses}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{row.draws}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{row.points_for}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{row.points_against}</td>
              <td className={`px-4 py-2 whitespace-nowrap text-sm font-medium ${row.difference > 0 ? 'text-green-600' : row.difference < 0 ? 'text-red-600' : 'text-gray-500'}`}>
                {row.difference > 0 ? '+' : ''}{row.difference}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
}

export default H2HTable;
