import React, { createContext, useState, useContext, useCallback } from 'react';

const NotificationContext = createContext();

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback((message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
    setTimeout(() => {
      setNotifications(prev => prev.filter(n => n.id !== id));
    }, 5000);
  }, []);

  return (
    <NotificationContext.Provider value={{ notifications, addNotification }}>
      {children}
      {notifications.length > 0 && (
        <div className="fixed bottom-4 right-4 z-50 max-w-md">
          {notifications.map(({ id, message, type }) => (
            <div 
              key={id} 
              className={`p-4 mb-2 rounded-md shadow-lg ${
                type === 'error' ? 'bg-red-100 text-red-700' : 
                type === 'success' ? 'bg-green-100 text-green-700' : 
                'bg-blue-100 text-blue-700'
              }`}
            >
              {message}
            </div>
          ))}
        </div>
      )}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  return useContext(NotificationContext);
}
