import React, { useState, useEffect } from 'react';

function CurrentGameweek({ user, isAdmin }) {
  const [currentGameweek, setCurrentGameweek] = useState(null);
  const [gameweeks, setGameweeks] = useState([]);
  const [timeUntilDeadline, setTimeUntilDeadline] = useState('');
  const [allGameweeks, setAllGameweeks] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeadlineMessage, setShowDeadlineMessage] = useState(true);

  useEffect(() => {
    if (user) {
      fetchGameweeks();
    }
  }, [user]);

  useEffect(() => {
    if (allGameweeks.length > 0) {
      calculateCurrentGameweek();
      filterGameweeks();
    }
  }, [user, allGameweeks, isAdmin]);

  const fetchGameweeks = async () => {
    try {
      setLoading(true);
      const response = await fetch('/api/gameweeks', {
        headers: {
          'Authorization': localStorage.getItem('token'),
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setAllGameweeks(data.gameweeks);
    } catch (error) {
      console.error('Error fetching gameweeks:', error);
      setError('Failed to fetch gameweeks');
    } finally {
      setLoading(false);
    }
  };

  const filterGameweeks = () => {
    const now = Date.now();
    const fiveDaysInMs = 5 * 24 * 60 * 60 * 1000;
    const filteredGameweeks = allGameweeks.filter(gw => 
      gw.deadline_time_epoch * 1000 <= now + fiveDaysInMs
    );
    setGameweeks(filteredGameweeks.reverse());
  }

  const calculateCurrentGameweek = () => {
    const now = Date.now();
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
    const fiveDaysInMs = 5 * 24 * 60 * 60 * 1000;

    const currentGW = allGameweeks.find(gw => {
      const timeSinceDeadline = now - gw.deadline_time_epoch * 1000;
      const timeUntilDeadline = gw.deadline_time_epoch * 1000 - now;
      return timeSinceDeadline > 0 && timeSinceDeadline <= threeDaysInMs || 
             (timeUntilDeadline > 0 && timeUntilDeadline <= fiveDaysInMs);
    }) || allGameweeks[allGameweeks.length - 1];

    setCurrentGameweek(currentGW);

  };

  useEffect(() => {
    let timer;
    if (currentGameweek) {
      const updateCountdown = () => {
        const now = Date.now();
        const deadline = currentGameweek.deadline_time_epoch * 1000;
        const timeLeft = deadline - now;
        setTimeLeft(timeLeft);
        
        if (timeLeft > 0) {
          const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

          setTimeUntilDeadline(`${days}d ${hours}h ${minutes}m ${seconds}s`);
          setShowDeadlineMessage(true);
        } else {
          const minutesPassed = Math.abs(Math.floor(timeLeft / (1000 * 60)));
          if (minutesPassed <= 60) {
            setTimeUntilDeadline('Deadline passed');
            setShowDeadlineMessage(true);
          } else {
            setShowDeadlineMessage(false);
          }
          clearInterval(timer);
        }
      };

      updateCountdown();
      timer = setInterval(updateCountdown, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentGameweek]);

  return { 
    currentGameweek, 
    gameweeks, 
    timeUntilDeadline, 
    allGameweeks, 
    loading, 
    error,
    showDeadlineMessage 
  };
}

export default CurrentGameweek;
